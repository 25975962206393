import React, { useState, useContext, useRef } from "react";
import { useAuth } from "hooks/useAuth";
import { useNavigate, Link, useLocation } from "react-router-dom";
import Logo from "assets/Logo";
import { NotificationContext } from "context/NotificationProvider";
import { InitialsAvatar } from "components/core/Avatars/InitialsAvatar";
import { generateInitialsFromText } from "helpers/stringUtilities";
import useClickOutside from "hooks/useClickOutside";
import { AppDataContext } from "context/AppDataProvider";

export default function Navbar() {
  const [openMenu, setOpenMenu] = useState(false);
  const [openProfileMenu, setOpenProfileMenu] = useState(false);
  const auth = useAuth();
  let navigate = useNavigate();
  const mainMenuRef = useRef(null);
  const mainMenuButtonRef = useRef(null);
  const profileMenuRef = useRef(null);
  const profileMenuButtonRef = useRef(null);
  useClickOutside(mainMenuRef, handleClickOutsideMainMenu);
  useClickOutside(profileMenuRef, handleClickOutsideProfileMenu);

  const { addNotification } = useContext(NotificationContext);
  const { offline, user } = useContext(AppDataContext);

  let location = useLocation();

  //#region event listeners

  const handleClickSignOut = (ev) => {
    ev.preventDefault();

    auth.signout((res) => {
      addNotification({
        key: "signed-out-notification",
        title: "Sign out success",
        theme: "success",
        description: "You have signed out.",
      });
      handleToggleProfileMenu();
      navigate("/");
    });
  };

  const handleToggleMainMenu = () => {
    setOpenMenu((current) => !current);
  };

  const handleToggleProfileMenu = () => {
    setOpenProfileMenu((current) => !current);
  };

  function handleClickOutsideMainMenu(ev) {
    if (
      !openMenu ||
      mainMenuButtonRef.current.contains(ev.target) // Don't update state if menu button is clicked to prevent double triggering.
    )
      return;
    setOpenMenu(false);
  }

  function handleClickOutsideProfileMenu(ev) {
    if (
      !openProfileMenu ||
      profileMenuButtonRef.current.contains(ev.target) // Don't update state if menu button is clicked to prevent double triggering.
    )
      return;
    setOpenProfileMenu(false);
  }

  const handleClickSyncQueuedData = async () => {
    if (
      window.confirm(
        "Are you sure you want to sync queued data? " +
        "This will send all queued data to the server. " +
        "Please ensure you have an reliable active connection."
      )
    ) {
      if (!navigator.onLine) {
        alert(
          "You are not online. Please connect to the internet and try again."
        );
        return;
      }

      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.controller?.postMessage({
          type: "REPLAY_REQUESTS",
        });
      }

      setOpenProfileMenu(false);
    }
  };

  //#endregion

  if (location.pathname.toLowerCase() === "/user/login") {
    // Don't show on login page
    return null;
  }

  return (
    <nav data-cy="navbar" className="bg-gray-900 relative z-50 print:hidden">
      <div className="px-2 sm:px-6 lg:px-8">
        <div className="relative flex items-center justify-between h-16">
          <div className="flex-1 flex items-center justify-start sm:items-stretch sm:justify-start">
            <div className="flex-shrink-0 flex items-center">
              <div className="text-gray-300">
                <Link to="/">
                  <Logo className="h-10 w-auto" />
                </Link>
              </div>
            </div>
          </div>
          <div
            className={`${
              !user ? "hidden" : ""
            } absolute z-50 inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0`}
          >
            {/* Profile dropdown */}
            <div className="ml-3 relative">
              <div>
                <button
                  ref={profileMenuButtonRef}
                  className={`bg-gray-800 flex text-sm rounded-full  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 ${
                    offline ? "focus:ring-gray-500" : "focus:ring-success-500"
                  }`}
                  id="user-menu"
                  aria-haspopup="true"
                  onClick={handleToggleProfileMenu}
                >
                  <span className="sr-only">Open user menu</span>
                  {/* <img
                    className="h-8 w-8 rounded-full"
                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixqx=lPPMWzHpli&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  /> */}
                  {user && (
                    <InitialsAvatar
                      initials={generateInitialsFromText(`${user.FullName}`)}
                    />
                  )}
                  <span className="flex h-3 w-3 absolute -right-1 bottom-0">
                    {!offline && (
                      <span
                        className={`animate-ping absolute inline-flex h-full w-full rounded-full bg-success-500 opacity-75`}
                      ></span>
                    )}
                    <span
                      className={`relative inline-flex rounded-full h-3 w-3 ${
                        offline ? "bg-gray-500" : "bg-success-500"
                      } ring-2 ring-gray-900`}
                    ></span>
                  </span>
                </button>
              </div>
              {openProfileMenu && (
                <div
                  ref={profileMenuRef}
                  className="transform origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 opacity-100 scale-100 z-10 divide-y divide-gray-100"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="user-menu"
                >
                  <div
                    className="block px-4 py-2 text-sm text-gray-700 bg-gray-100 hover:bg-white"
                    role="menuitem"
                  >
                    Status:{" "}
                    {offline ? (
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-200 text-gray-800">
                        Offline
                      </span>
                    ) : (
                      <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-success-200 text-success-800">
                        Online
                      </span>
                    )}
                  </div>
                  {/* <Link
                  to="/user/profile"
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  role="menuitem"
                  onClick={handleToggleProfileMenu}
                >
                  Your Profile
                </Link> */}
                  {/* <Link
                  to="/user/settings"
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                  role="menuitem"
                  onClick={handleToggleProfileMenu}
                >
                  Settings
                </Link> */}
                  <Link
                    to="/debug"
                    className="text-left block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    role="menuitem"
                  >
                    About App
                  </Link>
                  {navigator.onLine ? (
                    <div
                      className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                      role="menuitem"
                      onClick={handleClickSyncQueuedData}
                    >
                      Sync data
                    </div>
                  ) : null}
                  <Link
                    to="/"
                    className="text-left block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                    role="menuitem"
                    onClick={handleClickSignOut}
                  >
                    Sign out
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Mobile menu, show/hide based on menu state. --> */}
      <div
        ref={mainMenuRef}
        className={`max-w-7xl mx-auto ${openMenu ? "" : "hidden"}`}
        id="mobile-menu"
      >
        <div className="px-2 pt-2 pb-3 space-y-1">
          {/* <Link
            to="/"
            className="bg-gray-900 text-white block px-3 py-2 rounded-md text-base font-medium"
            onClick={handleToggleMainMenu}
          >
            Dashboard
          </Link> */}
          <Link
            to="/farms"
            className="bg-gray-800 text-white block px-3 py-2 rounded-sm text-base font-medium  focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary"
            onClick={handleToggleMainMenu}
          >
            Farms
          </Link>
        </div>
      </div>
    </nav>
  );
}
