import { Button } from "components/core";
import PageHeader from "components/PageHeader";
import { AppDataContext } from "context/AppDataProvider";
import { useLiveQuery } from "dexie-react-hooks";
import { localDateFromUnix, localDateToSQL } from "helpers/dateUtilities";
import { parseJSON } from "helpers/stringUtilities";
import React, { useContext, useEffect, useLayoutEffect } from "react";
import { workboxDB } from "../db";

const multipart = require("parse-multipart-data");
const version = require("../../package.json").version;

export const AppVersion = `v${version}`;

export interface ICachedRequest {
  name: string;
  container: string;
}

export default function Debug() {
  const { db, onSignOut, user } = useContext(AppDataContext) as any;

  const [downloadSpeed, setDownloadSpeed] = React.useState("");
  const [showExportButton, setShowExportButton] = React.useState(false);
  const [swStatus, setSwStatus] = React.useState("");
  const [cachedRequests, setCachedRequests] = React.useState<
    ICachedRequest[] | undefined
  >(undefined);
  const [hasBackgroundSyncSupport, setHasBackgroundSyncSupport] =
    React.useState(false);

  const importPendingDataTextAreaRef = React.useRef<HTMLTextAreaElement>(null);

  const pendingFormData = useLiveQuery(async () => {
    return (await db?.formdata?.toArray()) ?? [];
  });

  const queuedFormData = useLiveQuery(async () => {
    try {
      const requests = await workboxDB?.table("requests")?.toArray();
      
      const result = [];
      for (const record of requests) {
        const request = record.requestData;
        // convert request body from array buffer to string
        // const boundary = request.headers["content-type"].split(";")[1].split("=")[1];
        const boundary = multipart.getBoundary(request.headers["content-type"]);
        const bodyBuffer = Buffer.from(request.body);
        const parts = multipart.parse(bodyBuffer, boundary);
        const dataPart = parts.find(
          (part: any) => part.type === "application/json"
        )?.data;

        const data = parseJSON(Buffer.from(dataPart).toString("utf8"));
        if (!data) {
          console.error("Error parsing data", dataPart);
          continue;
        }

        result.push({
          id: record.id,
          queueName: record.queueName,
          timestamp: localDateToSQL(localDateFromUnix(record.timestamp.toString())),
          data,
        });

        /**
         * Uncomment to debug
         */
        // prettier-ignore
        // console.log(parts, dataPart, Buffer.from(dataPart).toString(), Buffer.from(request.body).toString());
      }

      return result;
    } catch (e) {
      //console.log(e);
    };
    
    return [];
  });

  const logs = useLiveQuery(async () => {
    return (await db?.logs?.toArray()) ?? [];
  });

  const handleSwUpdateAvailable = () => {
    setSwStatus("update_available");
  };

  const handleSwInstalling = () => {
    setSwStatus("installing");
  };

  useEffect(() => {
    async function updateCachedStorageDataState() {
      const cachedStorageData: {
        name: string;
        container: string;
      }[] = [];
      caches.keys().then((containerKeys) => {
        containerKeys.forEach((containerKey) => {
          caches.open(containerKey).then((cache) => {
            cache.keys().then((keys) => {
              keys.forEach((key) => {
                cachedStorageData.push({
                  name: key.url,
                  container: containerKey,
                });
              });
            });
          });
        });
      });

      setCachedRequests(cachedStorageData ?? null);
    }

    function updateDownloadSpeed() {
      const startTime = new Date().getTime();
      const download = new Image();
      download.onload = () => {
        const endTime = new Date().getTime();
        const duration = (endTime - startTime) / 1000;
        const bitsLoaded = download.width * download.height * 8;
        const speedBps = Number((bitsLoaded / duration).toFixed(2));
        const speedKbps = Number((speedBps / 1024).toFixed(2));
        const speedMbps = (speedKbps / 1024).toFixed(2);
        setDownloadSpeed(
          `Your download speed is: ${speedBps} bps (${speedKbps} kbps / ${speedMbps} Mbps)`
        );
      };
      download.src =
        "https://upload.wikimedia.org/wikipedia/commons/2/2c/Rotating_earth_%28large%29.gif?download";
    }

    document.addEventListener("serviceWorkerInstalling", handleSwInstalling);
    document.addEventListener("swUpdateAvailable", handleSwUpdateAvailable);

    updateCachedStorageDataState();
    updateDownloadSpeed();

    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.ready.then((reg) => {
        if ('sync' in reg) {
          setHasBackgroundSyncSupport(true);
        }
      });
    }

    return () => {
      document.removeEventListener(
        "serviceWorkerInstalling",
        handleSwInstalling
      );
      document.removeEventListener(
        "swUpdateAvailable",
        handleSwUpdateAvailable
      );
    };
  }, []);

  const isPendingFormDataLoaded = pendingFormData !== undefined;
  const isQueuedFormDataLoaded = queuedFormData !== undefined;
  const isUserLoaded = user !== undefined;
  const isCachedRequestsLoaded = cachedRequests !== undefined;
  const isDownloadSpeedLoaded = downloadSpeed !== "";
  const isLogsLoaded = logs !== undefined;
  useLayoutEffect(() => {
    if (
      isPendingFormDataLoaded &&
      isQueuedFormDataLoaded &&
      isUserLoaded &&
      isCachedRequestsLoaded &&
      isDownloadSpeedLoaded &&
      isLogsLoaded
    ) {
      setShowExportButton(true);
    }
  }, [
    isPendingFormDataLoaded,
    isQueuedFormDataLoaded,
    isUserLoaded,
    isCachedRequestsLoaded,
    isDownloadSpeedLoaded,
    isLogsLoaded,
  ]);

  const handleClickDeleteFormPendingDataItem = async (item: any) => {
    if (
      window.confirm(
        "Are you sure you wish to delete the DB data item? Deleting this is permanent and cannot be undone."
      )
    )
      await db?.formdata?.delete(item.ID);
  };

  const handleClickDeleteAllFormPendingData = async () => {
    if (
      window.confirm(
        "WARNING: Unless asked to do so by Unitas Support DO NOT continue. Ensure you have downloaded an 'Export' before clicking OK button. Are you sure you wish to delete all pending form data? Deleting this is permanent and cannot be undone."
      )
    )
      await db?.formdata?.clear();
  };

  const handleClickImportPendingData = async () => {
    const pendingData = JSON.parse(
      importPendingDataTextAreaRef.current?.value ?? "[]"
    );
    if (!Array.isArray(pendingData)) {
      alert("Invalid JSON data. Please paste a valid JSON array.");
      return;
    }

    db.formdata.bulkPut(pendingData).then(() => {
      console.log("Imported pending data to IndexedDB formdata table");
      alert("Imported pending data to IndexedDB formdata table");
    });

    // clear the textarea
    importPendingDataTextAreaRef.current!.value = "";
  };

  const handleSignOut = async () => {
    if (
      window.confirm(
        "Are you sure you wish to sign out? Signing out will delete all cached form data."
      )
    )
      onSignOut();
  };

  const handleClickExport = async () => {
    const exportDate = new Date().toISOString();
    const exportData = {
      "App Version": AppVersion,
      "Export Date": exportDate,
      User: user?.UserName,
      "User agent": navigator.userAgent,
      Platform: navigator.platform,
      Vendor: navigator.vendor,
      Language: navigator.language,
      "Cookies enabled": navigator.cookieEnabled.toString(),
      Online: navigator.onLine.toString(),
      "Service worker": navigator.serviceWorker?.controller?.state ? "active" : "not active",
      "Download speed": downloadSpeed,
      "Pending form data": pendingFormData,
      "Queued form data": queuedFormData,
      "Cached storage data": cachedRequests,
      Logs: logs,
    };

    const blob = new Blob([JSON.stringify(exportData)], {
      type: "application/json",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.download = `export-${user.UserName}-${exportDate}.json`;
    link.href = url;
    link.click();
  };

  const handleClickSyncQueuedData = async () => {
    if (
      window.confirm(
        "Are you sure you wish to sync queued data? This will send all queued data to the server."
      )
    ) {
      if (!navigator.onLine) {
        alert(
          "You are not online. Please connect to the internet and try again."
        );
        return;
      }

      if (queuedFormData?.length === 0) {
        alert("There is no queued data to sync.");
        return;
      }

      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.controller?.postMessage({
          type: "REPLAY_REQUESTS",
        });
      }
    }
  };

  const handleClickUpdateApp = async () => {
    navigator.serviceWorker.ready.then((reg: any) => {
      reg.waiting?.postMessage({ type: "SKIP_WAITING" });
      reg.waiting?.addEventListener(
        "statechange",
        (e: { target: { state: string } }) => {
          // Wait for new service worker to become active
          if (e.target.state === "activated") {
            window.location.reload();
          }
        }
      );
    });
  };

  return (
    <div className="flex-grow overflow-x-hidden">
      <div className="relative z-20">
        <PageHeader
          title="Debugging"
          className="py-6 px-4 tablet:px-6 desktop:px-8"
          subtitle={undefined}
          onClickBack={undefined}
        >
          <div className="flex flex-grow justify-end items-center">
            {showExportButton && (
              <Button
                onClick={handleClickExport}
                isFullWidth={undefined}
                theme={undefined}
                label={undefined}
                disabled={undefined}
                icon={undefined}
                iconPosition={undefined}
                options={undefined}
                optionsHeading={undefined}
                optionProps={undefined}
                showOptions={undefined}
                onShowOptions={undefined}
                onHideOptions={undefined}
                showStats={undefined}
                showSearch={undefined}
                optionsFilteredByMeta={undefined}
              >
                Export
              </Button>
            )}
          </div>
        </PageHeader>
      </div>
      <main className="flex flex-grow flex-col">
        <div className="overflow-hidden bg-white shadow tablet:rounded-lg">
          <div className="px-4 py-5 tablet:px-6">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Debug Information
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              App and data information to aid with debugging and conflict
              resolution.
            </p>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 tablet:p-0">
            <dl className="tablet:divide-y tablet:divide-gray-200">
            <div className="py-4 tablet:grid tablet:grid-cols-3 tablet:gap-4 tablet:py-5 tablet:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  App version
                </dt>
                <dd className="mt-1 text-sm text-gray-900 tablet:col-span-2 tablet:mt-0">
                  {AppVersion}
                </dd>
              </div>
              <div className="py-4 tablet:grid tablet:grid-cols-3 tablet:gap-4 tablet:py-5 tablet:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  User agent
                </dt>
                <dd className="mt-1 text-sm text-gray-900 tablet:col-span-2 tablet:mt-0">
                  {navigator.userAgent}
                </dd>
              </div>
              <div className="py-4 tablet:grid tablet:grid-cols-3 tablet:gap-4 tablet:py-5 tablet:px-6">
                <dt className="text-sm font-medium text-gray-500">Platform</dt>
                <dd className="mt-1 text-sm text-gray-900 tablet:col-span-2 tablet:mt-0">
                  {navigator.platform}
                </dd>
              </div>
              <div className="py-4 tablet:grid tablet:grid-cols-3 tablet:gap-4 tablet:py-5 tablet:px-6">
                <dt className="text-sm font-medium text-gray-500">Vendor</dt>
                <dd className="mt-1 text-sm text-gray-900 tablet:col-span-2 tablet:mt-0">
                  {navigator.vendor}
                </dd>
              </div>
              <div className="py-4 tablet:grid tablet:grid-cols-3 tablet:gap-4 tablet:py-5 tablet:px-6">
                <dt className="text-sm font-medium text-gray-500">Language</dt>
                <dd className="mt-1 text-sm text-gray-900 tablet:col-span-2 tablet:mt-0">
                  {navigator.language}
                </dd>
              </div>
              <div className="py-4 tablet:grid tablet:grid-cols-3 tablet:gap-4 tablet:py-5 tablet:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Cookie enabled
                </dt>
                <dd className="mt-1 text-sm text-gray-900 tablet:col-span-2 tablet:mt-0">
                  {navigator.cookieEnabled.toString()}
                </dd>
              </div>
              <div className="py-4 tablet:grid tablet:grid-cols-3 tablet:gap-4 tablet:py-5 tablet:px-6">
                <dt className="text-sm font-medium text-gray-500">Online</dt>
                <dd className="mt-1 text-sm text-gray-900 tablet:col-span-2 tablet:mt-0">
                  {navigator.onLine.toString()}
                </dd>
              </div>
              <div className="py-4 tablet:grid tablet:grid-cols-3 tablet:gap-4 tablet:py-5 tablet:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Browser supports background sync
                </dt>
                <dd className="mt-1 text-sm text-gray-900 tablet:col-span-2 tablet:mt-0">
                  {hasBackgroundSyncSupport.toString()}
                </dd>
              </div>
              <div className="py-4 tablet:grid tablet:grid-cols-3 tablet:gap-4 tablet:py-5 tablet:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Service worker
                </dt>
                <dd className="mt-1 text-sm text-gray-900 tablet:col-span-2 tablet:mt-0">
                  {navigator.serviceWorker?.controller?.state ? "active" : "not active"}
                </dd>
              </div>
              <div className="py-4 tablet:grid tablet:grid-cols-3 tablet:gap-4 tablet:py-5 tablet:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Pending form data
                </dt>
                <dd className="mt-1 text-sm text-gray-900 tablet:col-span-2 tablet:mt-0">
                  <ul className="divide-y divide-gray-200 rounded-md border border-gray-200">
                    {pendingFormData?.length ? (
                      pendingFormData?.map((item: any, index: number) => {
                        const dateApplies = localDateToSQL(
                          localDateFromUnix(item.DateApplies)
                        );
                        const lastModified = localDateToSQL(
                          localDateFromUnix(item.LastModified)
                        );

                        return (
                          <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                            <div className="w-full space-y-2">
                              <div>Farm: {item.FarmCode}</div>
                              <div>Form Type: {item.MenuOption}</div>
                              <div>Form: {item.FormName}</div>
                              <div>House: {item.HouseNumber}</div>
                              <div>Date Applies: {dateApplies}</div>
                              <div>Last Modified: {lastModified}</div>
                            </div>
                            <Button
                              onClick={() =>
                                handleClickDeleteFormPendingDataItem(item)
                              }
                              className=""
                              isFullWidth={false}
                              theme="danger"
                              label="delete item"
                              disabled={undefined}
                              icon={undefined}
                              iconPosition={undefined}
                              options={undefined}
                              optionsHeading={undefined}
                              optionProps={undefined}
                              showOptions={undefined}
                              onShowOptions={undefined}
                              onHideOptions={undefined}
                              showStats={undefined}
                              showSearch={false}
                              optionsFilteredByMeta={undefined}
                            >
                              Delete
                            </Button>
                          </li>
                        );
                      })
                    ) : (
                      <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                        No items found.
                      </li>
                    )}
                  </ul>
                  <Button
                    onClick={() => handleClickDeleteAllFormPendingData()}
                    className="mt-4"
                    isFullWidth={false}
                    theme="danger"
                    label="delete item"
                    disabled={undefined}
                    icon={undefined}
                    iconPosition={undefined}
                    options={undefined}
                    optionsHeading={undefined}
                    optionProps={undefined}
                    showOptions={undefined}
                    onShowOptions={undefined}
                    onHideOptions={undefined}
                    showStats={undefined}
                    showSearch={false}
                    optionsFilteredByMeta={undefined}
                  >
                    Delete all pending form data!!
                  </Button>
                </dd>
              </div>
              <div className="py-4 tablet:grid tablet:grid-cols-3 tablet:gap-4 tablet:py-5 tablet:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Queued form data
                </dt>
                <dd className="mt-1 text-sm text-gray-900 tablet:col-span-2 tablet:mt-0">
                  <ul className="divide-y divide-gray-200 rounded-md border border-gray-200">
                    {queuedFormData?.length ? (
                      queuedFormData?.map((item: any, index: number) => {
                        const formData = item.data;
                        return (
                          <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                            <div className="w-full space-y-2">
                              <div>Queue ID: {item.id}</div>
                              <div>Record ID: {formData.Data.ID}</div>
                              <div>PWA ID: {formData.PWAID}</div>
                              <div>Farm: {formData.FarmCode}</div>
                              <div>Form Type: {formData.FormType}</div>
                              <div>Menu Option: {formData.MenuOption}</div>
                              <div>Form: {formData.FormName}</div>
                              <div>House: {formData.Data.House}</div>
                              <div>
                                Date Applies: {formData.Data.DateApplies}
                              </div>
                              <div>
                                Last Modified: {formData.Data.LastModified}
                              </div>
                              <div>Raw Data: {JSON.stringify(formData)}</div>
                            </div>
                          </li>
                        );
                      })
                    ) : (
                      <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                        No items found.
                      </li>
                    )}
                  </ul>
                </dd>
              </div>

              <div className="py-4 tablet:grid tablet:grid-cols-3 tablet:gap-4 tablet:py-5 tablet:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Import Pending Data
                </dt>
                <dd className="mt-1 text-sm text-gray-900 tablet:col-span-2 tablet:mt-0">
                  <textarea
                    ref={importPendingDataTextAreaRef}
                    className="w-full h-32 border border-gray-200 rounded-md"
                    placeholder="Paste JSON data here"
                  ></textarea>
                  <Button
                    onClick={() => handleClickImportPendingData()}
                    className=""
                    isFullWidth={false}
                    theme="danger"
                    label="delete item"
                    disabled={navigator.onLine === false}
                    icon={undefined}
                    iconPosition={undefined}
                    options={undefined}
                    optionsHeading={undefined}
                    optionProps={undefined}
                    showOptions={undefined}
                    onShowOptions={undefined}
                    onHideOptions={undefined}
                    showStats={undefined}
                    showSearch={false}
                    optionsFilteredByMeta={undefined}
                  >
                    Import Pending data
                  </Button>
                </dd>
              </div>

              <div className="py-4 tablet:grid tablet:grid-cols-3 tablet:gap-4 tablet:py-5 tablet:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Sync queued form data
                </dt>
                <dd className="mt-1 text-sm text-gray-900 tablet:col-span-2 tablet:mt-0">
                  <Button
                    onClick={() => handleClickSyncQueuedData()}
                    className=""
                    isFullWidth={false}
                    theme="danger"
                    label="delete item"
                    disabled={
                      navigator.onLine === false || queuedFormData?.length === 0
                    }
                    icon={undefined}
                    iconPosition={undefined}
                    options={undefined}
                    optionsHeading={undefined}
                    optionProps={undefined}
                    showOptions={undefined}
                    onShowOptions={undefined}
                    onHideOptions={undefined}
                    showStats={undefined}
                    showSearch={false}
                    optionsFilteredByMeta={undefined}
                  >
                    Sync queued data
                  </Button>
                </dd>
              </div>
              <div className="py-4 tablet:grid tablet:grid-cols-3 tablet:gap-4 tablet:py-5 tablet:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  App update
                </dt>
                <dd className="mt-1 text-sm text-gray-900 tablet:col-span-2 tablet:mt-0">
                  {swStatus === "update_available" ? (
                    <Button
                      onClick={() => handleClickUpdateApp()}
                      className=""
                      isFullWidth={false}
                      theme="danger"
                      label="delete item"
                      disabled={navigator.onLine === false}
                      icon={undefined}
                      iconPosition={undefined}
                      options={undefined}
                      optionsHeading={undefined}
                      optionProps={undefined}
                      showOptions={undefined}
                      onShowOptions={undefined}
                      onHideOptions={undefined}
                      showStats={undefined}
                      showSearch={false}
                      optionsFilteredByMeta={undefined}
                    >
                      Update app
                    </Button>
                  ) : swStatus === "installing" ? (
                    <div>Installing update...</div>
                  ) : (
                    <div>App is up to date.</div>
                  )}
                </dd>
              </div>

              <div className="py-4 tablet:grid tablet:grid-cols-3 tablet:gap-4 tablet:py-5 tablet:px-6">
                <dt className="text-sm font-medium text-gray-500">Sign out</dt>
                <dd className="mt-1 text-sm text-gray-900 tablet:col-span-2 tablet:mt-0">
                  <Button
                    onClick={handleSignOut}
                    className=""
                    isFullWidth={false}
                    theme="danger"
                    label="sign out"
                    disabled={undefined}
                    icon={undefined}
                    iconPosition={undefined}
                    options={undefined}
                    optionsHeading={undefined}
                    optionProps={undefined}
                    showOptions={undefined}
                    onShowOptions={undefined}
                    onHideOptions={undefined}
                    showStats={undefined}
                    showSearch={false}
                    optionsFilteredByMeta={undefined}
                  >
                    Sign out &amp; delete local cache
                  </Button>
                </dd>
              </div>
              <div className="py-4 tablet:grid tablet:grid-cols-3 tablet:gap-4 tablet:py-5 tablet:px-6">
                <dt className="text-sm font-medium text-gray-500">
                  Download speed
                </dt>
                <dd className="mt-1 text-sm text-gray-900 tablet:col-span-2 tablet:mt-0">
                  {downloadSpeed}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </main>
    </div>
  );
}
